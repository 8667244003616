import { ContractToSignInfo } from 'src/app/_services/types/contract-template.types';
import { ContractType } from 'src/app/enums/contract-type.enum';
import {
  getHealthBaseInsuranceLabel,
  HealthBaseInsurance,
} from 'src/app/enums/health/base-insurance.enum';
import {
  getHealthOfferTierLabel,
  HealthOfferTier,
} from 'src/app/enums/health/health-offer-tier.enum';
import { InsuranceTypeEnum } from 'src/app/enums/insurance-type.enum';
import { SelectElement } from 'src/app/ui-kit/components/select/select.component';
import { AmbulanceIconComponent } from 'src/app/ui-kit/icons/ambulance-icon.component';
import { BedIconComponent } from 'src/app/ui-kit/icons/bed-icon.component';
import { BedsFourIconComponent } from 'src/app/ui-kit/icons/beds-four-icon.component';
import { BedsTwoIconComponent } from 'src/app/ui-kit/icons/beds-two-icon.component';
import { BodyMassageIconComponent } from 'src/app/ui-kit/icons/body-massage-icon.component';
import { CameraIconComponent } from 'src/app/ui-kit/icons/camera-icon.component';
import { CircleIconComponent } from 'src/app/ui-kit/icons/circle-icon.component';
import { CrossIconComponent } from 'src/app/ui-kit/icons/cross-icon.component';
import { DoctorIconComponent } from 'src/app/ui-kit/icons/doctor-icon.component';
import { DoctorsIconComponent } from 'src/app/ui-kit/icons/doctors-icon.component';
import { FamilyIconComponent } from 'src/app/ui-kit/icons/family-icon.component';
import { FitnessWeightIconComponent } from 'src/app/ui-kit/icons/fitness-weight-icon.component';
import { GlassesIconComponent } from 'src/app/ui-kit/icons/glasses-icon.component';
import { HelicopterIconComponent } from 'src/app/ui-kit/icons/helicopter-icon.component';
import { LawGavelIconComponent } from 'src/app/ui-kit/icons/law-gavel-icon.component';
import { MopIconComponent } from 'src/app/ui-kit/icons/mop-icon.component';
import { SmileIconComponent } from 'src/app/ui-kit/icons/smile-icon.component';
import { StethoscopeIconComponent } from 'src/app/ui-kit/icons/stethoscope-icon.component';

import { SelectElementMapper } from '../../_services/types/commons.type';
import { HospitalInsuranceEnum } from '../../enums/health/hospital-insurance.enum';
import {
  getHealthPrestationLabel,
  HealthPrestationsEnum,
} from '../../enums/health/prestations.enum';

export const HEALTH_INSURANCE_END_URL = 'maladie/fin';

export const HEALTH_INSURANCE_BASE_URL = 'maladie';
export const HEALTH_INSURANCE_FORM_BASE_URL = 'formulaire';
export const HEALTH_INSURANCE_OFFER_BASE_URL = 'offer';

export const HEALTH_INSURANCE_STEPS = {
  BASE: `${HEALTH_INSURANCE_BASE_URL}/${HEALTH_INSURANCE_FORM_BASE_URL}/`,
  MEMBERS_COUNT: { step: [1], url: 'nombre-personnes' },
  PERSONAL_INFO: { step: [2], url: 'informations' },
  OFFER_SEARCH: { step: [3], url: 'recherche-offres' },
  OFFERS: { step: [3], url: 'recherche-offres' },
};

export const HEALTH_OFFER_FORM_STEPS = {
  BASE: `${HEALTH_INSURANCE_BASE_URL}/${HEALTH_INSURANCE_OFFER_BASE_URL}/`,
  ACCOUNT: { step: [3, 3], url: 'compte' },
  LOGIN: { step: [3, 3], url: 'connexion' },
  REGISTER: { step: [3, 3], url: 'inscription' },
  FAMILY_MEMBERS: { step: [3, 3], url: 'membre-famille' },
  PERSONAL_INFO: { step: [3, 3], url: 'informations-personnelles' },
  HELSANA: { step: [3], url: 'helsana' },
  CSS: { step: [3], url: 'css' },
  SWICA: { step: [3], url: 'swica' },
  GM: { step: [3], url: 'groupemutuel' },
  SUMMARY: { step: [4], url: 'recapitulatif' },
  CONTRACT_SIGNATURE: { step: [4], url: 'signature-contrat' },
  CANCELLATION: { step: [4], url: 'resiliation' },
};

export const ASSURANCES_COMPLEMENTAIRES: SelectElementMapper<HealthPrestationsEnum> = {
  [HealthPrestationsEnum.FITNESS]: {
    value: HealthPrestationsEnum.FITNESS,
    label: getHealthPrestationLabel(HealthPrestationsEnum.FITNESS),
    iconComponent: FitnessWeightIconComponent,
    info: $localize`Cette option couvre généralement une partie ou la totalité des frais d'abonnement à des cours fitness reconnu par l'assureur. Elle peut également être appelée "promotion de la santé" auprès de certaines compagnies.`,
  },
  [HealthPrestationsEnum.MASSAGE]: {
    value: HealthPrestationsEnum.MASSAGE,
    label: getHealthPrestationLabel(HealthPrestationsEnum.MASSAGE),
    iconComponent: BodyMassageIconComponent,
    info: $localize`Cette option couvre les frais liés aux séances de massage thérapeutique ou de bien-être. Cela peut inclure différents types de massages tels que le massage suédois, le massage sportif, ou le massage relaxant.`,
  },
  [HealthPrestationsEnum.EYEWEAR]: {
    value: HealthPrestationsEnum.EYEWEAR,
    label: getHealthPrestationLabel(HealthPrestationsEnum.EYEWEAR),
    iconComponent: GlassesIconComponent,
    info: $localize`Cette option couvre une partie ou la totalité des frais liés à l'achat de lunettes de vue ou de lentilles de contact. Cela peut inclure les examens de la vue, les montures de lunettes, les verres correcteurs et les lentilles de contact.`,
  },
  [HealthPrestationsEnum.LEGAL_PROTECTION]: {
    value: HealthPrestationsEnum.LEGAL_PROTECTION,
    label: getHealthPrestationLabel(HealthPrestationsEnum.LEGAL_PROTECTION),
    iconComponent: LawGavelIconComponent,
    info: $localize`La protection juridique dans une assurance santé complémentaire en Suisse t'offre une assistance en cas de litige lié à des questions de santé, comme avec les prestataires de soins ou les assureurs. Elle couvre tes frais juridiques et te fournit des conseils pour résoudre les différends, te garantissant ainsi la protection de tes droits et de tes intérêts en matière de santé.`,
  },
  [HealthPrestationsEnum.REPATRIATION]: {
    value: HealthPrestationsEnum.REPATRIATION,
    label: getHealthPrestationLabel(HealthPrestationsEnum.REPATRIATION),
    iconComponent: AmbulanceIconComponent,
    info: $localize`Cette option offre une couverture pour les frais de rapatriement d'urgence en cas de blessure ou de maladie grave à l'étranger. Cela peut inclure les frais de transport médicalisé par avion ou par ambulance vers ton pays d'origine, ainsi que les frais médicaux associés au rapatriement.`,
  },
  [HealthPrestationsEnum.RESCUE]: {
    value: HealthPrestationsEnum.RESCUE,
    label: getHealthPrestationLabel(HealthPrestationsEnum.RESCUE),
    iconComponent: HelicopterIconComponent,
    info: $localize`Cette option offre une couverture pour les frais de secours en montagne ou en mer en cas d'urgence. Cela peut inclure les frais de recherche et de sauvetage, ainsi que les frais de transport vers un établissement médical approprié en cas d'accident ou de situation d'urgence en plein air.`,
  },
  [HealthPrestationsEnum.CHECK_UP]: {
    value: HealthPrestationsEnum.CHECK_UP,
    label: getHealthPrestationLabel(HealthPrestationsEnum.CHECK_UP),
    iconComponent: StethoscopeIconComponent,
    info: $localize`Cette option couvre les frais liés à des examens de santé préventifs, tels que des bilans de santé complets, des tests sanguins, des examens radiologiques et d'autres dépistages médicaux.`,
  },
  [HealthPrestationsEnum.DENTAL]: {
    value: HealthPrestationsEnum.DENTAL,
    label: getHealthPrestationLabel(HealthPrestationsEnum.DENTAL),
    iconComponent: SmileIconComponent,
    info: $localize`Cette option couvre les frais liés aux traitements orthodontiques et à la correction des problèmes dentaires tels que les appareils dentaires, les gouttières invisibles et autres traitements orthodontiques.`,
  },
  [HealthPrestationsEnum.HYGIENIST]: {
    value: HealthPrestationsEnum.HYGIENIST,
    label: getHealthPrestationLabel(HealthPrestationsEnum.HYGIENIST),
    iconComponent: MopIconComponent,
    info: $localize`Cette option couvre les frais liés aux soins dentaires préventifs, tels que les nettoyages professionnels, les détartrages, les traitements de prévention des caries et d'autres soins visant à maintenir une bonne santé bucco-dentaire.`,
  },
};

export const HOSPITALISATION: SelectElementMapper<HospitalInsuranceEnum> = {
  [HospitalInsuranceEnum.GENERAL]: {
    value: HospitalInsuranceEnum.GENERAL,
    label: $localize`Division commune`,
    iconComponent: BedsFourIconComponent,
    info: $localize`Tu as le choix de l'hôpital. Tu partages une chambre avec d'autres patients à l'hôpital. Les équipements sont basiques.C'est l'option la moins chère.`,
  },
  [HospitalInsuranceEnum.SEMI_PRIVATE]: {
    value: HospitalInsuranceEnum.SEMI_PRIVATE,
    label: $localize`Demi-privée`,
    iconComponent: BedsTwoIconComponent,
    info: $localize`Tu as le choix de l'hôpital. Tu partages la chambre avec un seul autre patient maximum. La chambre est un peu plus confortable. Tu bénéficies du libre choix du médecin. Un peu plus cher que la division commune.`,
  },
  [HospitalInsuranceEnum.PRIVATE]: {
    value: HospitalInsuranceEnum.PRIVATE,
    label: $localize`Privé`,
    iconComponent: BedIconComponent,
    info: $localize`Tu as le choix de l'hôpital. Tu as également ta propre chambre avec des équipements luxueux comme une télévision et un accès Internet. C'est plus confortable et privé. Tu bénéficies du libre choix du médecin. C'est l'option la plus chère.`,
  },
  [HospitalInsuranceEnum.NONE]: {
    value: HospitalInsuranceEnum.NONE,
    label: $localize`Sans assurance hospitalisation`,
    iconComponent: CrossIconComponent,
    info: $localize`Sans assurance hospitalisation : Tu n'as ni le choix de l'hôpital, ni du médecin. Tu dois assumer personnellement les coûts supplémentaires d'une hospitalisation.`,
  },
};

export const INSURERS_LIST = [
  'Agrisano',
  'AMB Assurance SA',
  'Aquilana',
  'Assura-Basis SA',
  'Atupri Gesundheistversicherung AG',
  'Avenir Krakenversicherung AG',
  'Bildende Künstler/Innen',
  'Birchmeier',
  'CONCORDIA',
  'CSS',
  'Easy Sana Krankenversicherung AG',
  'EGK',
  'Einsielder Krankenkasse',
  'GALENOS AG',
  'Gewerbliche',
  'Glamer',
  'Helsana',
  'Hotela',
  'KLuG',
  'KPT',
  'Lumneziana',
  'Luzerner Hinterland',
  'Metallbaufirmen',
  'Mutuel Krankenversicherung AG',
  'Mutuelle Neuchâteloise',
  'ÖKK',
  'Philos Krankenversicherung AG',
  'rhenusana',
  'sana24',
  'sanavals',
  'Sanitas',
  'SLKK',
  'sodalis',
  'Steffisburg',
  'Sumiswalder',
  'SUPRA-1846 SA',
  'SWICA',
  "Vallée d'Entremont",
  'Visana',
  'Visperterminen',
  'vita surselva',
  'Vivacare',
  'Vivao Sympany',
  'Wädenswil',
];

export const NO_INSURER = 'Aucun';

export const DEDUCTIBLE_CHILD_VALUES = [0, 100, 200, 300, 400, 500, 600];
export const DEDUCTIBLE_VALUES = [300, 500, 1000, 1500, 2000, 2500];
export const DEDUCTIBLE_OPTIONS = [
  'CHF 300',
  'CHF 500',
  'CHF 1000',
  'CHF 1500',
  'CHF 2000',
  'CHF 2500',
];
export const DEDUCTIBLE_CHILD_OPTIONS = [
  'CHF 0',
  'CHF 100',
  'CHF 200',
  'CHF 300',
  'CHF 400',
  'CHF 500',
  'CHF 600',
];
export const CONTRACTS_TO_SIGN: ContractToSignInfo[] = [
  {
    name: 'Article 45 maladie',
    type: ContractType.ARTICLE_45,
    insuranceType: InsuranceTypeEnum.HEALTH,
  },
  {
    name: 'Mandat gestion maladie',
    type: ContractType.MANAGEMENT_AGREEMENT,
    insuranceType: InsuranceTypeEnum.HEALTH,
  },
];

export const HOSPTAL_OFFERS = [
  'demi-privée',
  'privée',
  'eco',
  'global smart',
  'hospita',
  "assurance d'hospitalisation",
];

export const HEALTH_SUMMARY_LEGAL_TEXT = $localize`Selon la Loi fédérale sur la protection des données (LPD), les données personnelles doivent être conservées uniquement aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées. C'est pourquoi LTY s'engage a détruire les données relatives au questionnaire médical dès que le processus est achevé et que ces informations ne sont plus nécessaires.`;

export const FIRST_STEP_TITLE = $localize`Personnes à assurer`;

export const PERSON_STEPS_MAPPING = {
  personalData: $localize`Données personnelles `,
  prices: $localize`Tarifs proposés `,
  questionnaire: $localize`Questions contractuelles `,
};

export const BASE_PERSON_STEPS = Object.values(PERSON_STEPS_MAPPING);

export const LOGIN_STEP_TITLE = $localize`Connexion / Inscription`;

export const MEMBER_STEPS = [$localize`Membre de la famille`, $localize`Document de mandat`];

export const RECAP_TITLE = $localize`Récapitulatif`;

export const CHILD_LABEL = $localize`enfant `;

export const ADULT_LABEL = $localize`adulte `;

export const REIMBURSED_SUM_HELP_TEXT = $localize`La franchise est le montant que tu t'engages à payer avant que l'assureur ne prenne en charge tes frais médicaux. Plus ta franchise est haute, moins ta prime mensuelle le sera et vice versa. Notre conseil : Si tes frais médicaux ne dépassent pas 1'500 CHF par année, tu peux sélectionner une franchise à 2'500 CHF`;

export const ACCIDENT_HELP_TEXT = $localize`Tu devrais souscrire à une couverture d'accident dans le cas où tu ne travailles pas pour le même employeur au moins 8 heures par semaine (par exemple si il s'agit d'un indépendant, d'un enfant, d'un étudiant, d'un retraité ou d'un pensionnaire de l'assurance invalidité) : ce qui veut dire que si tu es employé plus de 8 heures par semaine, tu peux valider la case "non".`;

export const BASE_INSURANCE_HELP_TEXT = $localize`Lorsque tu choisis ton assurance de base, tu peux choisir comment tu veux consulter ton médecin : soit par téléphone, soit chez ton médecin traitant, ou dans un groupe spécifique de médecins. Ces choix peuvent faire varier le coût de ta prime. Il est important de choisir celui qui te convient le mieux en fonction de tes préférences et de ton budget. Tu trouveras des informations détaillées sur chaque modèle sur l'icône "i"`;

export const HOSPITALISATION_HELP_TEXT = $localize`L'assurance hospitalisation complémentaire te couvre pour les frais supplémentaires en cas d'hospitalisation, que l'assurance de base ne prend pas en charge. Sans cette assurance, tu devras payer toi-même ces frais, ce qui peut être un vrai poids financier si quelque chose d'imprévu arrive. LTY te recommande de souscrire à une assurance hospitalisation complémentaire pour te protéger de ces dépenses. Tu as le choix entre différentes options : une division commune, demi-privée, ou privée. Plus tu optes pour une chambre privée, plus tu gagnes en confort, mais le coût est aussi plus élevé. À toi de choisir selon tes préférences et ton budget !`;

export const SUPPLEMENTARY_INSURANCE_HELP_TEXT = $localize`S'assurer davantage avec une couverture complémentaire te protège contre les gros frais médicaux imprévus (comme le sauvetage ou le rapatriement) et t’offre plus de choix pour tes soins de santé, avec la prise en charge partielle des frais de fitness ou de médecine alternative, par exemple. Tout cela peut rendre ta vie plus facile et te permettre d’anticiper tes dépenses de santé. LTY te propose des packages adaptés à tes besoins et à ton budget : le package "Basic" pour l'essentiel, "Confort" pour une couverture équilibrée, ou "Premium" pour une protection maximale. À toi de choisir la formule qui te convient le mieux !`;

export const AGE_HELP_TITLE = $localize`Pourquoi me demandes-tu ma date de naissance ?`;

export const AGE_HELP_CONTENT = $localize`Selon l'âge, les besoins de santé varient. Les jeunes peuvent avoir besoin d'une couverture différente de celle des personnes plus âgées. Cette information nous aide à te proposer une assurance adaptée à tes besoins spécifiques.`;

export const GENDER_HELP_TITLE = $localize`Pourquoi dois-je indiquer mon genre ?`;

export const GENDER_HELP_CONTENT = $localize`Le genre est pris en compte par les assureurs car les hommes et les femmes peuvent avoir des besoins de santé différents. Cela nous permet de te fournir une offre d'assurance plus précise et juste.`;

export const BASE_INSURANCE_ELEMENTS: SelectElement<HealthBaseInsurance>[] = [
  {
    value: HealthBaseInsurance.TELEMEDECINE,
    label: getHealthBaseInsuranceLabel(HealthBaseInsurance.TELEMEDECINE),
    iconComponent: CameraIconComponent,
    info: $localize`Tu as l'obligation de d'abord consulter des médecins en ligne ou par téléphone pour des conseils médicaux, des diagnostics et des prescriptions, ce qui peut être pratique et rapide, surtout pour des problèmes de santé mineurs.`,
  },
  {
    value: HealthBaseInsurance.FREE_CHOICE_OF_DOCTOR,
    label: getHealthBaseInsuranceLabel(HealthBaseInsurance.FREE_CHOICE_OF_DOCTOR),
    iconComponent: DoctorIconComponent,
    info: $localize`Tu es libre de choisir n'importe quel médecin ou spécialiste, même s'ils ne font pas partie d'un réseau spécifique. Cela te donne la liberté de consulter le professionnel de santé que tu préfères, quel que soit l'endroit où il exerce.`,
  },
  {
    value: HealthBaseInsurance.FAMILY,
    label: getHealthBaseInsuranceLabel(HealthBaseInsurance.FAMILY),
    iconComponent: FamilyIconComponent,
    info: $localize`Tu peux choisir d'avoir un médecin de famille qui sera ton principal prestataire de soins. Ce médecin gérera ton dossier médical et te fournira des soins de santé continus et coordonnés.`,
  },
  {
    value: HealthBaseInsurance.HEALTHCARE_NETWORK,
    label: getHealthBaseInsuranceLabel(HealthBaseInsurance.HEALTHCARE_NETWORK),
    iconComponent: DoctorsIconComponent,
    info: $localize`C'est un modèle où tu choisis un médecin principal dans un groupe de médecins. Ce médecin te dirige vers des spécialistes si besoin.`,
  },
];

export const HEALTH_OFFER_TIERS: SelectElement<HealthOfferTier>[] = [
  {
    value: HealthOfferTier.BASIC,
    label: getHealthOfferTierLabel(HealthOfferTier.BASIC),
    iconComponent: CircleIconComponent,
  },
  {
    value: HealthOfferTier.COMFORT,
    label: getHealthOfferTierLabel(HealthOfferTier.COMFORT),
    iconComponent: CircleIconComponent,
  },
  {
    value: HealthOfferTier.PREMIUM,
    label: getHealthOfferTierLabel(HealthOfferTier.PREMIUM),
    iconComponent: CircleIconComponent,
  },
];
