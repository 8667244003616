import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-circle-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3335 22C17.8563 22 22.3335 17.5228 22.3335 12C22.3335 6.47715 17.8563 2 12.3335 2C6.81065 2 2.3335 6.47715 2.3335 12C2.3335 17.5228 6.81065 22 12.3335 22Z"
        [attr.stroke]="color"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
})
export class CircleIconComponent extends BaseIconComponent {}
