export enum HealthBaseInsurance {
  TELEMEDECINE = 'TELEMEDECINE',
  FAMILY = 'FAMILY',
  HEALTHCARE_NETWORK = 'HEALTHCARE_NETWORK',
  FREE_CHOICE_OF_DOCTOR = 'FREE_CHOICE_OF_DOCTOR',
}

export const getHealthBaseInsuranceLabel = (value: HealthBaseInsurance): string => {
  switch (value) {
    case HealthBaseInsurance.TELEMEDECINE:
      return $localize`Télé-médecine`;
    case HealthBaseInsurance.FREE_CHOICE_OF_DOCTOR:
      return $localize`Libre choix du médecin`;
    case HealthBaseInsurance.HEALTHCARE_NETWORK:
      return $localize`Réseaux de soins`;
    case HealthBaseInsurance.FAMILY:
      return $localize`Médecin de famille`;
    default:
      return '';
  }
};
