import { Component } from '@angular/core';

import { BaseIconComponent } from './base-icon.component';

@Component({
  selector: 'app-family-icon',
  template: `
    <svg
      [attr.height]="height"
      [attr.width]="width"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1627_15247)">
        <path
          d="M14.5602 6.01974C15.0943 6.01974 15.5272 5.58681 15.5272 5.05278C15.5272 4.51874 15.0943 4.08582 14.5602 4.08582C14.0262 4.08582 13.5933 4.51874 13.5933 5.05278C13.5933 5.58681 14.0262 6.01974 14.5602 6.01974Z"
          [attr.fill]="color"
        />
        <path
          d="M12.8944 10.7133C12.8606 10.899 12.8487 11.0364 12.6312 11.0939C12.6204 11.0975 12.606 11.1011 12.5952 11.1011L12.7944 10.3715C12.8764 10.6014 12.8821 10.6512 12.8944 10.7133Z"
          [attr.fill]="color"
        />
        <path
          d="M15.3377 6.50418C15.329 6.50418 15.3204 6.50414 15.3116 6.50435C15.288 6.50471 15.2655 6.50878 15.2428 6.51242C15.2164 6.50788 15.1896 6.50414 15.1619 6.5041L13.9532 6.50183C13.9255 6.5018 13.8987 6.5054 13.8722 6.50986C13.8496 6.50612 13.827 6.50198 13.8034 6.50151C13.7948 6.50126 13.786 6.50122 13.7773 6.50122C12.2313 6.49834 11.5007 9.02857 11.4221 9.31866C11.3655 9.52746 11.4562 9.73946 11.6293 9.85027L12.0019 9.90988C12.1671 9.86949 12.3073 9.74774 12.3552 9.57206C12.5463 8.87172 12.9937 7.90144 13.4677 7.5787L13.4645 9.28086L12.7574 11.8782L13.4596 11.8795L13.4557 13.9947C13.4551 14.2617 13.6713 14.4785 13.9382 14.479C14.2052 14.4795 14.4221 14.2635 14.4226 13.9965L14.4265 11.8813L14.6683 11.8818L14.6643 13.9969C14.6638 14.2639 14.8799 14.4808 15.1469 14.4813C15.4139 14.4818 15.6307 14.2657 15.6312 13.9987L15.6352 11.8836L16.3376 11.8849L15.6401 9.28496L15.6433 7.58284C16.106 7.9004 16.5398 8.83687 16.735 9.53401L16.7348 9.63693C16.7348 9.63693 16.7362 9.63812 16.7387 9.64964L16.7801 9.64971C16.7985 9.69054 16.8201 9.72787 16.8479 9.76113L16.8479 9.78622C16.8788 9.79476 17.0147 9.88861 17.0515 9.92774L17.0516 9.90841C17.1446 9.94214 17.2394 9.94894 17.3404 9.92202C17.6395 9.84174 17.6546 9.65237 17.6952 9.4097C17.6606 9.04966 16.853 6.50702 15.3377 6.50418Z"
          [attr.fill]="color"
        />
        <path
          d="M10.5695 8.20207C10.9654 8.20207 11.2863 7.88117 11.2863 7.48531C11.2863 7.08946 10.9654 6.76855 10.5695 6.76855C10.1737 6.76855 9.85278 7.08946 9.85278 7.48531C9.85278 7.88117 10.1737 8.20207 10.5695 8.20207Z"
          [attr.fill]="color"
        />
        <path
          d="M7.98867 11.132L7.99033 10.2438C7.88269 10.5824 7.78721 10.8318 7.98867 11.132ZM11.3698 10.6205L11.8876 12.5489L11.3662 12.5479L11.3632 14.1157C11.3628 14.3135 11.2007 14.475 11.0029 14.4746C10.8052 14.4743 10.6473 14.3121 10.6476 14.1144L10.6506 12.5465L10.4708 12.5462L10.4678 14.1141C10.4675 14.3118 10.3053 14.4733 10.1076 14.473C9.90981 14.4726 9.75191 14.3105 9.75227 14.1127L9.75523 12.5449L9.2338 12.5439L9.75883 10.6175L9.76117 9.35893C9.40833 9.59563 9.07616 10.3142 8.93493 10.8353C8.89871 10.9647 8.79428 11.0544 8.67195 11.0865L8.39875 11.0429C8.26943 10.9599 8.20139 10.8016 8.24485 10.647C8.30281 10.4314 8.84209 8.55534 9.98919 8.5575L10.0072 8.55753C10.0251 8.56117 10.0431 8.5612 10.0611 8.5648C10.079 8.56128 10.1006 8.55771 10.1186 8.55775L11.014 8.55944C11.0356 8.55948 11.0572 8.56311 11.0751 8.56675C11.0931 8.56322 11.1075 8.56322 11.1255 8.55966L11.1471 8.55969C11.1938 8.55976 11.2405 8.56347 11.2873 8.57075C11.1788 8.86181 11.1101 9.10261 11.0739 9.22479C10.987 9.55545 11.105 9.90087 11.3707 10.1064L11.3698 10.6205ZM12.8943 10.7132C12.8421 11 12.7307 11.1981 12.4154 11.0828L12.4153 11.0971C12.3798 11.0615 12.3249 11.033 12.3292 11.0394C12.3112 11.0142 12.2897 10.9998 12.2645 10.9926L12.2646 10.9746C12.243 10.9494 12.2287 10.9242 12.2144 10.8918L12.1856 10.8917L12.182 10.8881C12.182 10.8845 12.182 10.8845 12.182 10.8845L12.1822 10.8054C12.1322 10.6399 12.0678 10.4528 11.989 10.2729L12.0178 10.2765C12.2669 10.2115 12.4553 10.1263 12.6046 9.88928C12.7032 10.1048 12.8487 10.4835 12.8943 10.7132Z"
          [attr.fill]="color"
        />
        <path
          d="M3.55509 6.18966C4.08913 6.18966 4.52205 5.75673 4.52205 5.2227C4.52205 4.68866 4.08913 4.25574 3.55509 4.25574C3.02106 4.25574 2.58813 4.68866 2.58813 5.2227C2.58813 5.75673 3.02106 6.18966 3.55509 6.18966Z"
          [attr.fill]="color"
        />
        <path
          d="M6.67735 9.49991C6.63652 9.3468 6.41469 8.571 5.98942 7.87055C5.99201 7.86609 5.9941 7.86159 5.9967 7.85712C5.61455 7.2349 5.06987 6.67517 4.3444 6.6738L4.33299 6.67377L4.31841 6.67373L4.307 6.6737C4.285 6.67366 4.26329 6.67766 4.24299 6.68158C4.21873 6.67755 4.19302 6.67348 4.16901 6.67344L4.1576 6.67341L2.95847 6.67118L2.94706 6.67114C2.92305 6.6711 2.89731 6.6751 2.87305 6.67902C2.85274 6.67503 2.83107 6.67092 2.80908 6.67089L2.79763 6.67085L2.78308 6.67082L2.77167 6.67078C2.0462 6.66941 1.49947 7.22712 1.11499 7.84791C1.11758 7.85241 1.11963 7.85687 1.12222 7.86134C0.731875 8.49897 0.431059 9.38072 0.415039 9.56828C0.466519 9.8724 0.526783 10.0969 0.894811 10.0976C0.951115 10.0977 1.00526 10.087 1.05728 10.0698L1.05724 10.0892C1.09443 10.0461 1.16571 10.005 1.17435 10.005C1.20038 9.97475 1.22857 9.95747 1.26108 9.94671L1.26111 9.92291C1.2893 9.89048 1.31104 9.85156 1.32843 9.81041L1.37174 9.81048V9.804L1.37393 9.79965L1.37411 9.69572C1.57086 9.00455 2.00239 8.08126 2.46211 7.75845L2.45048 13.9725C2.44998 14.241 2.66612 14.458 2.93248 14.4585C2.93489 14.4585 2.93687 14.4572 2.93925 14.4571C2.94163 14.4572 2.94361 14.4585 2.94602 14.4585C3.21238 14.459 3.42936 14.2429 3.42986 13.9743L3.43591 10.752L3.66483 10.7525L3.65878 13.9747C3.65828 14.2433 3.87442 14.4602 4.14079 14.4607C4.1432 14.4607 4.14518 14.4595 4.14759 14.4594C4.15 14.4595 4.15195 14.4608 4.15436 14.4608C4.42072 14.4613 4.63769 14.2451 4.6382 13.9766L4.64983 7.76252C5.10832 8.08706 5.5364 9.01197 5.73055 9.70385L5.73037 9.80778L5.73253 9.81214V9.81862L5.7758 9.81869C5.79304 9.85988 5.81464 9.8989 5.84272 9.93144L5.84269 9.95524C5.87516 9.96615 5.90328 9.9835 5.92919 10.0139C5.9378 10.0139 6.00893 10.0552 6.04598 10.0985L6.04601 10.079C6.4391 10.2109 6.7663 9.85991 6.67735 9.49991Z"
          [attr.fill]="color"
        />
        <path
          d="M7.14815 8.09073C7.55991 8.09073 7.89371 7.75693 7.89371 7.34517C7.89371 6.93341 7.55991 6.59961 7.14815 6.59961C6.73639 6.59961 6.40259 6.93341 6.40259 7.34517C6.40259 7.75693 6.73639 8.09073 7.14815 8.09073Z"
          [attr.fill]="color"
        />
        <path
          d="M7.75629 8.46328C8.12669 8.46397 8.43557 8.65153 8.68678 8.91811C8.5138 9.12633 8.37311 9.36343 8.26482 9.58616C8.17515 9.46732 8.08544 9.36649 7.99205 9.30158L7.98309 14.0949C7.98269 14.2999 7.81698 14.4686 7.612 14.4682C7.6084 14.4682 7.6084 14.4646 7.60484 14.4646L7.60124 14.4682C7.39625 14.4678 7.22756 14.2985 7.22795 14.0935L7.2326 11.6087L7.05641 11.6084L7.05177 14.0932C7.05137 14.2982 6.88566 14.4669 6.68071 14.4665C6.67711 14.4665 6.67712 14.4629 6.67352 14.4629L6.66992 14.4665C6.46137 14.4661 6.29627 14.2968 6.29663 14.0918L6.30254 10.9417L6.40301 11.057L6.40416 10.4457C7.37011 10.1277 7.02819 9.2478 6.69198 8.4613L7.6233 8.46303C7.6413 8.46307 7.65927 8.4667 7.67723 8.4703C7.6952 8.46678 7.7096 8.46318 7.7276 8.46321L7.75629 8.46328Z"
          [attr.fill]="color"
        />
      </g>
      <defs>
        <clipPath id="clip0_1627_15247">
          <rect width="18" height="18" fill="white" transform="translate(0.0551758 0.283447)" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class FamilyIconComponent extends BaseIconComponent {}
